import axios from 'axios';
/*
export default { 
    async GetUser(vue) {
        // Get the access token from the auth wrapper
        const apiToken = await vue.$auth.getTokenSilently();
        // Use Axios to make a call to the API
        const { data } = await axios.get(`${process.env.VUE_APP_ROOT_API}/user`, {
          headers: {
            Authorization: `Bearer ${apiToken}`    // send the access token through the 'Authorization' header
          }
        });
        return data;
    }
};

*/
export default {
    async GetUser(vue) {
        return await this.callApi(vue, "/user")
    },
    async callApi(vue, path) {
        // Get the access token from the auth wrapper
        const apiToken = await vue.$auth.getTokenSilently();
        // Use Axios to make a call to the API
        const { data } = await axios.get(`${process.env.VUE_APP_ROOT_AZURE_API}` + path, {
        headers: {
            Authorization: `Bearer ${apiToken}`    // send the access token through the 'Authorization' header
        }
        });
        return data;
    } 
}
