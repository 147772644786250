/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin'; 
import App from './App.vue';

// router setup
import router from './routes/router'; //<!-- error -->
import i18n from './i18n';
import './registerServiceWorker'

//api
import VentureAmpApi from './api/ventureAmpApi'

// Import the Auth0 configuration
import { domain, clientId, audience } from "./auth/auth_config.json";
// Import the plugin here
import { Auth0Plugin } from "./auth";

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);


// Install the authentication plugin here

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// API Plugin
/*
const plugin = {
  install (Vue) {
    Vue.VentureAmpApi = {}
    Vue.VentureAmpApi.Api = VentureAmpApi
    Vue.prototype.$VentureAmpApi = {}
    Vue.prototype.$VentureAmpApi.Api = VentureAmpApi
  }
}
Vue.use(plugin)
*/

Vue.use(VentureAmpApi)

Vue.config.productionTip = false;

Vue.prototype.$prelaunch = true;

/* eslint-disable no-new */

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n
});

window.DevMode = Vue.config.devtools;

if (Vue.config.devtools)
{
  window.App = Vue;
}
