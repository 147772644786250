<template>
  <div class=" container">
    <!-- START  NAVBAR -->
    <nav class="title navbar navbar-transparent navbar-expand-lg fixed-top navbar-custom sticky sticky-light" id="navbar" >
      <div class="">
            <a class="navbar-brand logo text-uppercase" href="index.html">
                <!--<img src="img/logo/Logo-txt-m-blk.png" alt="" height="48" />-->
                <div>
                    <img>
                </div>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="mdi mdi-menu"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mx-auto" id="navbar-navlist">
                    <li class="nav-item">
                        <a class="nav-link" href="#home">Home</a>
                    </li>
                    <li class="nav-item" v-if="!$prelaunch">
                        <a class="nav-link" href="#services">Services</a>
                    </li>
                    <li class="nav-item" v-if="!$prelaunch">
                        <a class="nav-link" href="#work">Works</a>
                    </li>
                    <li class="nav-item" v-if="!$prelaunch">
                        <a class="nav-link" href="#client">Client</a>
                    </li>
                    <li class="nav-item" v-if="!$prelaunch">
                        <a class="nav-link" href="#price">Pricing</a>
                    </li>
                    <li class="nav-item" v-if="!$prelaunch">
                        <a class="nav-link" href="#faq">Faq</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://blog.ventureamp.io" target="_blank">Blog</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contact">Contact</a>
                    </li>
                </ul>
                <!--
                <div>
                    <ul class="top-right text-end list-unstyled list-inline mb-0 nav-social d-none d-lg-block">
                        <li class="list-inline-item"><a href="" class="facebook"><i class="mdi mdi-facebook"></i></a>
                        </li>
                        <li class="list-inline-item"><a href="" class="twitter"><i class="mdi mdi-twitter"></i></a></li>
                        <li class="list-inline-item"><a href="" class="instagram"><i class="mdi mdi-instagram"></i></a>
                        </li>
                    </ul>
                </div>
                -->
            </div>
      
      </div>
    </nav>
    <!-- END NAVBAR -->

    <!-- START HOME -->
    <section class="section bg-home align-items-center d-flex" id="home">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="text-center text-white">
                        <h1 class="home-title text-uppercase">Amp your scale up phase</h1>
                        <div class="home-border my-3 mt-4"></div>
                        <p class="home-subtitle pt-4 mx-auto">We create better outcomes for scale ups by helping you take your ideas through to execution. Our network of specialists ensure you have the support you need to turn your business up to 11.</p>
                                                
                        <div class="pt-4 mt-3 home-button" v-if="!$prelaunch">
                            <a href="#" class="btn btn-primary btn-round text-uppercase">Get Started Now

                                <span class="play-shadow ms-2" data-bs-toggle="modal"
                                    data-bs-target="#watchvideomodal"><span class="home-btn"><i
                                            class="mdi mdi-play text-center"></i></span></span>

                                <!-- Modal -->
                                <div class="modal fade bd-example-modal-lg" id="watchvideomodal" data-keyboard="false"
                                    tabindex="-1" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg">
                                        <div class="modal-content home-modal">
                                            <div class="modal-header border-0">
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <video id="VisaChipCardVideo" class="video-box" controls>
                                                <source src="https://www.w3schools.com/html/mov_bbb.mp4"
                                                    type="video/mp4">
                                                <!--Browser does not support <video> tag -->
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="title">
                            <div class="title-border"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END HOME -->

    <!-- START SERVICES -->
    <section class="title section" id="services" v-if="!$prelaunch">
        <div class="container">
            <div class="row justify-content-center ">
                <div class="col-lg-8">
                    <div class="title-box text-center">
                        <h5 class="sub-title text-primary text-uppercase">Best Services</h5>
                        <h3 class="mt-3 fw-normal">Creative Services with & digital business</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pt-3">
                <div class="col-lg-4">
                    <div class="service-box mt-4">
                        <div class="services-icon">
                            <i class="pe-7s-diamond"></i>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-3 f-17 mt-4">Digital Design</h5>
                            <p class="text-muted mb-0"> Some quick example text to build on the card title and make up
                                the bulk of the card content Moltin gives you the platform.</p>
                            <div class="mt-3">
                                <a href="" class="text-primary f-14 fw-normal">Learn More <i
                                        class="mdi mdi-arrow-right ms-1"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="service-box mt-4">
                        <div class="services-icon">
                            <i class="pe-7s-science"></i>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-3 f-17 mt-4">Awesome Support</h5>
                            <p class="text-muted mb-0">It is a paradisematic country, in which roasted parts of
                                sentences fly into your mouth leaeve Finibus Bonorum for the far World.</p>
                            <div class="mt-3">
                                <a href="" class="text-primary f-14 fw-normal">Learn More <i
                                        class="mdi mdi-arrow-right ms-1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="service-box active mt-4">
                        <div class="services-icon">
                            <i class="pe-7s-display2"></i>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-3 f-17 mt-4">Unlimited Colors</h5>
                            <p class="text-muted mb-0"> Credibly brand standards to compliant users without extensible
                                services.
                                Anibh euismod tincidunt words ut laoreet.</p>
                            <div class="mt-3">
                                <a href="" class="text-primary f-14 fw-normal">Learn More <i
                                        class="mdi mdi-arrow-right ms-1"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="service-box mt-4">
                        <div class="services-icon">
                            <i class="pe-7s-news-paper"></i>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-3 f-17 mt-4">Truly Multipurpose</h5>
                            <p class="text-muted mb-0">Even the all-powerful Pointing has no control about the blind
                                texts it is an almost unortho exact original form graphic.</p>
                            <div class="mt-3">
                                <a href="" class="text-primary f-14 fw-normal">Learn More <i
                                        class="mdi mdi-arrow-right ms-1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="service-box mt-4">
                        <div class="services-icon">
                            <i class="pe-7s-piggy"></i>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-3 f-17 mt-4">Strategy Solutions</h5>
                            <p class="text-muted mb-0"> Separated they live in Bookmarksgrove right at the coast
                                of the Semantics, a large lange ocean necessary regelialia.</p>
                            <div class="mt-3">
                                <a href="" class="text-primary f-14 fw-normal">Learn More <i
                                        class="mdi mdi-arrow-right ms-1"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="service-box mt-4">
                        <div class="services-icon">
                            <i class="pe-7s-plane"></i>
                        </div>
                        <div class="mt-3">
                            <h5 class="mb-3 f-17 mt-4">Strategy Solutions</h5>
                            <p class="text-muted mb-0">Question Marks and devious Semikoli, but the Little Blind Text
                                didn’t
                                listen. She packed her predefined chunks seven versalia.</p>
                            <div class="mt-3">
                                <a href="" class="text-primary f-14 fw-normal">Learn More <i
                                        class="mdi mdi-arrow-right ms-1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END SERVICES -->

    <!-- START HOW IT WORKS -->
    <section class="title section bg-light" id="work" v-if="!$prelaunch">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-7">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">Easy Proccess</h5>
                        <h3 class="mt-2 fw-normal">So... How Does it Works?</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pt-3">
                <div class="col-lg-4">
                    <div class="work-box work-border text-center p-4">
                        <div class="work-img">
                            <img src="img/title/work/img-1.png" alt="" class="img-fluid">
                        </div>

                        <div class="work-contain">
                            <h1 class="work-count text-muted display-2 fw-normal">01</h1>
                            <div class="work-desc">
                                <h4 class="f-17">Tell us your idea</h4>
                                <p class="text-muted mt-4 pt-2">It uses a dictionary sit consectetuer amget adipiscing
                                    elit
                                    aenean from coodo tend predefined ligula accident.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="work-box work-border text-center p-4">
                        <div class="work-img">
                            <img src="img/title/work/img-2.png" alt="" class="img-fluid">
                        </div>
                        <div class="work-contain">
                            <h1 class="work-count text-muted display-2 fw-normal">02</h1>
                            <div class="work-desc">
                                <h4 class="f-17">Debut with users</h4>
                                <p class="text-muted mt-4 pt-2">Phasellus ultricies magna vitae maximus congue lectus ex
                                    aliquet sem
                                    convallis aliquam Ut sit accumsan.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="work-box text-center p-4">
                        <div class="work-img">
                            <img src="img/title/work/img-3.png" alt="" class="img-fluid">
                        </div>
                        <div class="work-contain">
                            <h1 class="work-count text-muted display-2 fw-normal">03</h1>
                            <div class="work-desc">
                                <h4 class="f-17">Research and Develop</h4>
                                <p class="text-muted mt-4 pt-2">Consectetuer adicing commodo ligula eget dolor aenean
                                    massa cum sociis natoque
                                    montes nascetur quam felis.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-12">
                    <div class="mt-4 text-center">
                        <a href="#" class="btn btn-primary btn-round">View More <i
                                class="mdi mdi-arrow-right ps-1"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END HOW IT WORKS -->

    <!-- START VIDEO SECTION -->
    <section class=" section-lg bg-video" v-if="!$prelaunch">
        <div class="bg-overlay"></div>
        <div class="container title">
            <div class="row justify-content-center text-center">
                <div class="col-lg-7">
                    <div class="title-box">
                        <div>
                            <a href="" class="play-shadow" data-bs-toggle="modal"
                                data-bs-target="#watchvideomodal"><span class="video-play-icon"><i
                                        class="mdi mdi-play text-center"></i></span></a>

                            <!-- Modal -->
                            <div class="modal fade bd-example-modal-lg" id="watchvideomodal" data-keyboard="false"
                                tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg">
                                    <div class="modal-content home-modal">
                                        <div class="modal-header border-0">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <video id="VisaChipCardVideo" class="video-box" controls>
                                            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
                                            <!--Browser does not support <video> tag -->
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 class="text-white mt-5 fw-normal">Beautifull simple handcrafted templates for your website
                        </h3>
                        <p class="text-white-50 text-uppercase text-center pt-3">Unlimited Power And Customization</p>
                        <div class="title-border"></div>

                    </div>
                </div>
            </div>
          </div>
    </section>
    <!-- END VIDEO SECTION -->

    <!-- START CLIENTS -->
    <section class="title section  bg-light" id="client" v-if="!$prelaunch">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-7">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">Our Clients</h5>
                        <h3 class="mt-2 fw-normal">What they say about us
                            honest reviews</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mt-5 pt-4">
                <div class="col-lg-12">
                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="client-box mt-4">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <div class="client-img mt-4">
                                                <img src="title/images/users/img-1.jpg" class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="mt-4">
                                                <div class="client-content">
                                                    <h5 class="f-19 fw-normal line-height_1_6">“Our task musat bear to
                                                        free ourselves by widening our circle of comsion to embrace all
                                                        living creatures and the whole
                                                        and quis consectetur nunc sit amet semper justo.
                                                        scelerisque pretium dolor, sit amet vehicula pelleque nature and
                                                        its beauty.”
                                                    </h5>

                                                    <div class="client-icon mt-4 pt-2">
                                                        <i class="mdi mdi-format-quote-close"></i>
                                                    </div>

                                                    <h5 class="f-17 mt-3 mb-2">Brandon Carney</h5>
                                                    <p class="text-primary mb-0 f-14">- Designer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="carousel-item">
                                <div class="client-box mt-4">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <div class="client-img mt-4">
                                                <img src="title/images/users/img-2.jpg" class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="mt-4">
                                                <div class="client-content">
                                                    <h5 class="f-19 fw-normal line-height_1_6">“I feel confident
                                                        imposing change on myself. It's a lot more fun proging than
                                                        looking back. That's why I
                                                        ultricies enim at carney malesuada creatures and the whole of
                                                        quis
                                                        consectetur nunc diam on tortor semper throw curve balls.”
                                                    </h5>

                                                    <div class="client-icon mt-4 pt-2">
                                                        <i class="mdi mdi-format-quote-close"></i>
                                                    </div>

                                                    <h5 class="f-17 mt-3 mb-2">Jeremiah Eskew</h5>
                                                    <p class="text-primary mb-0 f-14">- Developer</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="carousel-item">
                                <div class="client-box mt-4">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <div class="client-img mt-4">
                                                <img src="title/images/users/img-3.jpg" class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-7">

                                            <div class="mt-4">
                                                <div class="client-content">
                                                    <h5 class="f-19 fw-normal line-height_1_6">"The auropean languages
                                                        are members andf the Same
                                                        family their separate existence is ae myth For science, music,
                                                        sport,
                                                        etc, Read europe
                                                        convals feugiat egdgfet cursus tellus their languages common
                                                        sagittis
                                                        praesent peesque words."
                                                    </h5>

                                                    <div class="client-icon mt-4 pt-2">
                                                        <i class="mdi mdi-format-quote-close"></i>
                                                    </div>

                                                    <h5 class="f-17 mt-3 mb-2">Zachary Tevis</h5>
                                                    <p class="text-primary mb-0 f-15">- Manager</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="carousel-item">
                                <div class="client-box mt-4">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <div class="client-img mt-4">
                                                <img src="title/images/users/img-4.jpg" class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="mt-4">
                                                <div class="client-content">
                                                    <h5 class="f-19 fw-normal line-height_1_6">"The auropean languages
                                                        are members of the Same family their separate existence is a
                                                        myth For science, music, sport, etc, our
                                                        europe convals feugiat egdgfet cursus tellus their languages
                                                        common sagittis praesent peesque words."
                                                    </h5>

                                                    <div class="client-icon mt-4 pt-2">
                                                        <i class="mdi mdi-format-quote-close"></i>
                                                    </div>

                                                    <h5 class="f-17 mt-3 mb-2">William Alderman</h5>
                                                    <p class="text-primary mb-0 f-14">- Ceo</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <ol class="carousel-indicators mb-0 rounded">
                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"><img
                                    src="title/images/users/img-1.jpg" alt="" class=" testi-img img-fluid mx-auto d-block">
                            </li>
                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"><img
                                    src="title/images/users/img-2.jpg" alt="" class=" testi-img img-fluid mx-auto d-block">
                            </li>
                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"><img
                                    src="title/images/users/img-3.jpg" alt="" class=" testi-img img-fluid mx-auto d-block">
                            </li>
                            <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"><img
                                    src="title/images/users/img-4.jpg" alt="" class=" testi-img img-fluid mx-auto d-block">
                            </li>
                        </ol>
                    </div>
                </div>

            </div>

            <div class="row mt-5 pt-3">
                <div class="col-lg-3">
                    <div class="client-images mt-4">
                        <img src="img/title/clients/1.png" alt="logo-img" class="mx-auto img-fluid d-block">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="client-images mt-4">
                        <img src="img/title/clients/2.png" alt="logo-img" class="mx-auto img-fluid d-block">
                    </div>
                </div>
                <div class="col-lg-3 ">
                    <div class="client-images mt-4">
                        <img src="img/title/clients/3.png" alt="logo-img" class="mx-auto img-fluid d-block">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="client-images mt-4">
                        <img src="img/title/clients/4.png" alt="logo-img" class="mx-auto img-fluid d-block">
                    </div>
                </div>
            </div>


        </div>
    </section>
    <!-- END CLIENTS -->

    <!-- START PRICE -->
    <section class="title section" id="price" v-if="!$prelaunch">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-7">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">Best Price</h5>
                        <h3 class="mt-2 fw-normal">Best Pricing Package Start
                            Business</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>

            <div class="row mt-5 pt-4">
                <div class="col-lg-4">
                    <div class="price-box mt-4">
                        <h4 class="pricing-title mb-0">Freelancer</h4>
                        <h1 class="fw-normal mb-0 mt-3">$9.99<sup class="text-muted f-15"> /Month</sup></h1>
                        <p class="text-muted mt-3 pb-3">For small teams trying out softland for an unlimited period of
                            time</p>
                        <hr class="mt-3">
                        <div class="price-features mt-4 pt-3">
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">2</span> Website</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">30 GB</span> Storage</p>
                            <p class="text-muted"><i class="mdi mdi-close-circle text-danger f-17 me-2 "></i><span
                                    class="text-dark fw-normal">Unmetered</span> Bandwidth</p>
                            <p class="text-muted"><i class="mdi mdi-close-circle text-danger f-17 me-2"></i><span
                                    class="text-dark fw-normal">Email</span> 1 Year trail</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">Free domain</span> annual plan</p>
                        </div>
                        <div class="mt-5 text-center">
                            <a href="" class="btn btn-outline-primary w-100">Start with Deazy</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="price-box mt-4">
                        <div class="pricing-badge">
                            <span class="badge">Featured</span>
                        </div>
                        <h4 class="pricing-title mb-0">Starter</h4>
                        <h1 class="fw-normal mb-0 mt-3 text-primary">$29.99<sup class="text-muted f-15"> /Month</sup>
                        </h1>
                        <p class="text-muted mt-3 pb-3">For larger businesses or those with onal administration needs
                        </p>
                        <hr class="mt-3 pricing-active-color">
                        <div class="price-features mt-4 pt-3">
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">2</span> Website</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">30 GB</span> Storage</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">Unmetered</span> Bandwidth</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">Email</span> 1 Year trail</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">Free domain</span> annual plan</p>
                        </div>
                        <div class="mt-5 text-center">
                            <a href="" class="btn btn-primary w-100">Start with Deazy</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="price-box mt-4">
                        <h4 class="pricing-title mb-0">Enterprice</h4>
                        <h1 class="fw-normal mb-0 mt-3">$49.99<sup class="text-muted f-15"> /Month</sup></h1>
                        <p class="text-muted mt-3 pb-3">For extra large businesses or those in regulated industries</p>
                        <hr class="mt-3">
                        <div class="price-features mt-4 pt-3">
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">2</span> Website</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">30 GB</span> Storage</p>
                            <p class="text-muted"><i class="mdi mdi-close-circle text-danger f-17 me-2 "></i><span
                                    class="text-dark fw-normal">Unmetered</span> Bandwidth</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">Email</span> 1 Year trail</p>
                            <p class="text-muted"><i class="mdi mdi-check-circle text-primary f-17 me-2"></i><span
                                    class="text-dark fw-normal">Free domain</span> annual plan</p>
                        </div>

                        <div class="mt-5 text-center">
                            <a href="" class="btn btn-outline-primary w-100">Start with Deazy</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- END PRICE -->

    <!-- START SIGN IN FORM -->
    <section class="title section bg-light" v-if="!$prelaunch">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h3 class="fw-normal">Start Your Free Trial</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mt-5">
                <div class="col-lg-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-7">
                            <form class="sign-form">
                                <div class="form-group">
                                    <label for="semail"><i class="mdi mdi-email-outline sign-icon"></i></label>
                                    <input type="email" class="form-control" id="semail" placeholder="Email">
                                </div>
                                <div class="form-group">
                                    <label for="fname"><i class="mdi mdi-account sign-icon"></i></label>
                                    <input type="text" class="form-control" id="fname" placeholder="First Name">
                                </div>
                                <div class="form-group">
                                    <label for="cname"><i class="mdi mdi-hospital-building sign-icon"></i></label>
                                    <input type="text" class="form-control" id="cname" placeholder="Company Name">
                                </div>
                                <div class="form-group">
                                    <label for="mnumber"><i class="mdi mdi-cellphone sign-icon"></i></label>
                                    <input type="text" class="form-control" id="mnumber" placeholder="Mobile Name">
                                </div>
                                <div>
                                    <button class="btn btn-primary w-100">Sign Up</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END SIGN IN FORM -->

    <!-- START FAQ -->
    <section class="title section" id="faq" v-if="!$prelaunch">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">More Question</h5>
                        <h3 class="mt-2 fw-normal">Frequently Asked Questions</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>
            <div class="row mt-5 pt-4 align-items-center">
                <div class="col-lg-6">
                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="featutes-box active p-4 mt-4">
                                <h3 class="text-primary">370+</h3>
                                <h5 class="f-17 mt-2 text-primary fw-normal">Happy Clients</h5>
                                <p class="text-muted mt-2 mb-0">Suspendisse potenti aliquam vulputate quis felis.</p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="featutes-box p-4 mt-4">
                                <h3>2M</h3>
                                <h5 class="f-17 mt-2 fw-normal">Download</h5>
                                <p class="text-muted mt-2 mb-0">Curabitur pulvinar in commodo Integer placera tellus.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="featutes-box p-4 mt-4">
                                <h3>4500+</h3>
                                <h3 class="f-17 mt-2 fw-normal">Happy Users</h3>
                                <p class="text-muted mt-2 mb-0">Proin dictum ipsum vestibulum soudin sagittis est. </p>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="featutes-box p-4 mt-4">
                                <h3>5+</h3>
                                <h3 class="f-17 mt-2 fw-normal">Years of expe.</h3>
                                <p class="text-muted mt-2 mb-0">Sodales maximus ex lectus venenatis lacus.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-lg-6">
                    <div class="accordion faq-box mt-4" id="accordionExample">
                        <div class="accordion-item mt-4">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <h5 class="mb-0 f-16">How many variations exist? </h5> <i
                                        class="mdi mdi-chevron-down f-20 ms-auto"></i>

                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p class="text-muted mb-0">You want customer to your store. Easily publish your
                                        coupans and when a user has manage to catch one of your coupens,
                                        the coupens wil be deducted from your on coupens
                                        consectetur adipisicing eli.Lorem ipsum dolor sit
                                        the amet, consectetur adipisicing eli.Lorem account at Clooger.</p>

                                    <p class="text-muted mt-2 mb-0">
                                        Aliquam rutrum felis et magna fringilla, vitae semper nunc ornare. Praesent
                                        vitae felis vitae tellus euismod viverra ac at sapien
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item mt-4">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <h5 class="mb-0 f-16"> Is safe use Lorem Ipsum?</h5> <i
                                        class="mdi mdi-chevron-down f-20 ms-auto"></i>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p class="text-muted mb-0">Itaque earum rerum hic tenetur sapiente delectut
                                        reiciendis voluptatibus perspiciatis unde omnis iste natus error sit maiores
                                        alias consequatur perferendisthat doloribus asperiores repellat.

                                        Nam libero tempore cum soluta nobis eligendi optio cumque nihil impedit
                                        minusidquod maxime placeat facere possimus.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item mt-4">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <h5 class="mb-0 f-16">When can be used? </h5> <i
                                        class="mdi mdi-chevron-down f-20 ms-auto"></i>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p class="text-muted mb-0">Temporibus autem quibusdam a aut officiis debitis rerum
                                        necessitatibus veniet ut et voluptates repudiandae sint a molestiae recusandae
                                        itaque earum rerum hic tenetur a sapiente delectus ut at aut reiciendis
                                        voluptatibus maiores
                                        alias consequatur perferendis doloribus asperiores rerum necessitat
                                        saepeeveniet. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- END FAQ -->

    <!-- START GET STARTED -->
    <section class="section-lg bg-cta" v-if="!$prelaunch">
        <div class="bg-overlay"></div>
          <div class="containerX">
            <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                    <div class="title-box">
                        <h3 class="text-white fw-normal">Let's get started with VentureAmp</h3>
                        <p class="text-white-50 text-center pt-3">We inspire, develop and impliment growth strategies for startups.
                            Leveraging a proven methodology that delivers results through direct and channel sales stategies, strategic and management accounting, modern software development practises, securityn risk, complaince, and human resources and culture, we ensure growing companies achive thier potential.                            
                        </p>
                        <div class="title-border"></div>

                        <div class="mt-5">
                            <a href="#" class="btn btn-outline-custom btn-round">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
          </div>
    </section>
    <!-- END GET STARTED -->

    <!-- START BIOS -->
    <section class="section" id="medium">
        <div class="container">
            <div class="title row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">Our Team</h5>
                        <h3 class="mt-2 fw-normal">On demand C-Suite expertise and proven scale-up experience</h3>
                    </div>
                </div>
            </div>
            <div class="row pt-4">

                <div class="col-lg-3">
                    <div class="mt-2">
                        <div class="title services-icon">
                            <img src="https://miro.medium.com/fit/c/200/200/1*CS2wXWi1DwmrgqSktlDjhw.jpeg" class="bio-picture">
                        </div>
                        <div class="mt-3">
                            <h5 class="title mb-3 f-17 mt-4">James Keppel</h5>
                            <p class="text-muted mb-0">Cyber Strategy, Information Technology and Information Security Risk.</p>
                            <div class="mt-3">
                                <p class="text-50">
James' provides strategic security consultancy for high-risk industries, information security businesses, regulated entities, and global enterprises facing unique security and privacy challenges.
<br /><br />
The founder of the Information Security Consultancy Catalyst Logic, a Certified Information Systems Security Professional (CISSP), and Certified Information Systems Auditor (CISA), with over 15 years’ experience in the Information Security Industry.
<br /><br />
James’ clients benefit from experience developed though roles such as the Chief Security Officer at a payments company that secures credit card payment for some of Australia’s largest corporations, and an Information Security Manager for a data protection company that secures millions of Australians personal information. James has developed security programmes that meet or exceed ISO 27001, ISO 9001, and PCI-DSS, Privacy Act and GDPR compliance across a range of industries, including those in the information security space. 
<br /><br />
James’ professional industry memberships include ISACA, AISA, (ISC)², PPN and CSA.
                                <p/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="mt-2">
                        <div class="title services-icon">
                            <img src="https://miro.medium.com/fit/c/200/200/1*XxLmogVdrZCziJmSN6vG7Q@2x.jpeg" class="bio-picture">
                        </div>
                        <div class="mt-3">
                            <h5 class="title mb-3 f-17 mt-4">Jules Grove</h5>
                            <p class="text-muted mb-0">Portfolio CFO.</p>
                            <div class="mt-3">
                                <p class="text-50">
With over 20 years’ experience in the accounting profession working with a wide range of public and private entities, Jules is the founder of sidekickCFO, a boutique consultancy that helps scale ups to use their accounting and finance resources as a business partner to amplify growth.
<br /><br />
Jules was the inaugural CFO for SecureCo, a FinTech based in Sydney which saw 1000% growth and raised over $7mil in equity during his time in the role.
<br /><br />
Prior to founding sidekickCFO, Jules held a senior position at PricewaterhouseCoopers working across a wide range of industries including investment management, real estate, private equity, construction, manufacturing and retail.  Jules worked with some of Australia’s most significant companies including Westpac, Macquarie and Coles Myer as well as many small to medium enterprises and start up entities.
<br /><br />
Jules has a particular interest in for-purpose and social enterprises, using accounting and finance methodologies to help maximise outcomes.
<br /><br />
Jules is a Chartered Accountant and hold a Bachelor of Commerce from James Cook University.

                                <p/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="mt-2">
                        <div class="title services-icon">
                            <img src="https://miro.medium.com/fit/c/200/200/1*HPVPQS9CG5UXu3erBaPT9A.jpeg" class="bio-picture">
                        </div>
                        <div class="mt-3">
                            <h5 class="title mb-3 f-17 mt-4">Mark Fredrickson</h5>
                            <p class="text-muted mb-0">Sales, Marketing, Product and Revenue.</p>
                            <div class="mt-3">
                                <p class="text-50">
Mark is a creative and innovative sales and marketing specialist who has built market share for businesses across a number of global markets. Key strengths are building brands that resonate with customers and step changing sales teams performances to deliver scalable growth.
<br /><br />
Through responding to your environment, actively listening to your team and maintaining a focus on improving the customer experience, Mark consistently managed to deliver strong commercial results across industry verticals and sectors.  He has worked with start-ups, national companies and global multinationals in North America, Europe, Asia Pacific and Oceania.
<br /><br />
Mark holds a Masters in Strategic Marketing Planning from Victoria University and graduated from the Executive program of Negotiations at Harvard Law School. He is also a published thought leader in the prestigious European Journal of Marketing and is ready to help your business move forward. 
                                <p/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="mt-2">
                        <div class="title services-icon">
                            <img src="https://miro.medium.com/fit/c/200/200/1*etnXokGqrcZySY9UYqMcsw.jpeg" class="bio-picture">
                        </div>
                        <div class="mt-3">
                            <h5 class="title mb-3 f-17 mt-4">Lauren Pearson</h5>
                            <p class="text-muted mb-0">Executive Coach and Transformational People and Culture Expert.</p>
                            <div class="mt-3">
                                <p class="text-50">
Lauren is the founder of Cult of Monday, a People &amp; Culture Consultancy, providing outsourced, end-to-end P&amp;C services and project support to organisations firmly in the scale up phase.  We work with leadership teams to develop and execute on organisational objectives, strategies and initiatives that will help them to achieve their strategic outcomes, and create an awesome place to work.
<br /><br />
Lauren coaches leadership teams to evolve their leadership skills, hone their performance, and deliver on their potential. She has global experience in a range of corporate environments including Technology, Financial Services, and Professional Services; and works with numerous technology businesses who are growing.
<br /><br />
She holds a Masters in Psychotherapy from Middlesex University, and Certifications in Coaching and assessment tools. She is a commercial leader who partners with organisations to deliver growth to your business, your employees and your bottom line.

                                <p/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title text-center">
                <div class="title-border"></div>
            </div>
        </div>
    </section>
    <!-- END BIOS -->

    <!-- START BLOG -->
    <section class="title section" id="medium">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">Our Blogs</h5>
                        <h3 class="mt-2 fw-normal">Our latest blog posts, insights and articles</h3>
                    </div>
                </div>
                    <div id="medium-widget"></div>
                    <div class="title">
                        <div class="title-border"></div>
                    </div>
            </div>
        </div>
    </section>
    <!-- END BLOG -->

    <!-- START TAG -->
    <section class="title section" id="contact">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h3>Support. Direction. Growth.</h3>
                        <div class="title-border"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END TAG -->

    <!-- START CONTACT -->
    <section class="title section" id="contact">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-12">
                    <div class="title-box">
                        <h5 class="sub-title text-primary text-uppercase">Contact us</h5>
                    </div>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-lg-4">
                    <div class="contact-content mt-4">
                        <div class="mt-4 pt-1" v-if="!$prelaunch">
                            <div class="contact-icon float-start pe-4">
                                <i class="mdi mdi-phone"></i>
                            </div>

                            <div class="contact-info">
                                <h5 class="f-16 mb-0 fw-normal">Phone Number</h5>
                                <p class="text-muted mb-0 f-14">+00 620-468-6348</p>
                            </div>
                        </div>

                        <div class="mt-4 pt-1"  v-if="!$prelaunch">
                            <div class="contact-icon float-start pe-4">
                                <i class="mdi mdi-email"></i>
                            </div>

                            <div class="contact-info">
                                <h5 class="f-16 mb-0 fw-normal">Email Address</h5>
                                <p class="text-muted mb-0 f-14">info@ventureamp.io</p>
                            </div>
                        </div>

                        <div class="mt-4 pt-1">
                            <div class="contact-icon float-start pe-4">
                                <i class="mdi mdi-map"></i>
                            </div>

                            <div class="contact-info">
                                <h5 class="f-16 mb-0 fw-normal">Location</h5>
                                <p class="text-muted mb-0 f-14">Sydney, Australia.</p>
                            </div>
                        </div>

                        <div class="mt-4 pt-1" v-if="!$prelaunch">
                            <div class="contact-icon float-start pe-4">
                                <i class="mdi mdi-calendar-range"></i>
                            </div>

                            <div class="contact-info">
                                <h5 class="f-16 mb-0 fw-normal">Email Time</h5>
                                <p class="text-muted mb-0 f-14">Mon - Fri 09:00 - 7:00</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="custom-form mt-4">
                        <form method="post" name="myForm" onsubmit="return validateForm()">
                            <p id="error-msg"></p>
                            <div id="simple-msg"></div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mt-2">
                                        <input name="name" id="name" type="text" class="form-control"
                                            placeholder="Your name">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mt-2">
                                        <input name="email" id="email" type="email" class="form-control"
                                            placeholder="Your email">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mt-2">
                                        <input type="text" class="form-control" id="subject"
                                            placeholder="Your Subject.." />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mt-2">
                                        <textarea name="comments" id="comments" rows="3" class="form-control"
                                            placeholder="Your message..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-12 text-right">
                                    <input type="submit" id="submit" name="send"
                                        class="submitBnt btn btn-rounded btn-primary" value="Send Message">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- END CONTACT -->

    <section class="bg-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="mt-4">
                        <h5 class="f-17 fw-normal">About company</h5>
                        <p class="text-50 mt-3 pt-1">
                            VentureAmp is an Australian co-operative who work with scale-up around strategic direction and execution towards VC, and pre-IPO phases.
                        </p>
                        </div>
                </div>

                <div class="col-lg-7 offset-lg-1">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="mt-4" v-if="!$prelaunch">
                                <h5 class="f-17 fw-normal">Support</h5>
                                <ul class="list-unstyled footer-link mt-3 mb-0">
                                    <li><a href="">Pricing</a></li>
                                    <li><a href="">Facebook Integration</a></li>
                                    <li><a href="">Application</a></li>
                                    <li><a href="">Woodpress Program</a></li>
                                    <li><a href="">Business Marketing</a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mt-4 ps-0 ps-lg-5"  v-if="!$prelaunch">
                                <h5 class="f-17 fw-normal">More Info</h5>
                                <ul class="list-unstyled footer-link mt-3 mb-0">
                                    <li><a href="">Pricing</a></li>
                                    <li><a href="">For Marketing</a></li>
                                    <li><a href="">For CEOs</a></li>
                                    <li><a href="">For Agencies</a></li>
                                    <li><a href="">Our Apps</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mt-4 ps-0 ps-lg-5"  v-if="!$prelaunch">
                                <h5 class="f-17 fw-normal">Resources</h5>
                                <ul class="list-unstyled footer-link mt-3 mb-0">
                                    <li><a href="">Form validation</a></li>
                                    <li><a href="">Pricacy Policy</a></li>
                                    <li><a href="">Terms &amp; Conditions</a></li>
                                    <li><a href="">Design Defined</a></li>
                                    <li><a href="">Marketplace</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <hr class="my-5">

            <div class="row">
                <div class="col-lg-12">
                    <img src="title/images/logo-light.png" alt="" height="24">
<!--
                    <p class="text-white-50 float-end mb-0">
                        2021 © Deazy. Design by Themesdesign
                    </p>
-->
                </div>

            </div>

        </div>
    </section>

    <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share>
  </div>

</template>
<script>
/*import { app } from './js/app';*/

import Blog from '../Pages/js/blog';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import SidebarShare from '../Layout/SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}


import { BaseProgress } from 'src/components';
import SidebarFixedToggleButton from '../Layout/SidebarFixedToggleButton.vue';

export default {
  name: 'home-page',
  components: {
    BaseProgress,
    SidebarFixedToggleButton,
    SidebarShare/*,
    app*/
  },
    data() {
    return {
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
    MediumWidget.Init({renderTo: '#medium-widget', params: {"resource":"https://blog.ventureamp.io/","postsPerLine":3,"limit":8,"picture":"small","fields":["description","author"],"ratio":"square"}})
  }
};


//  Window scroll sticky class add
function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
    ) {
        navbar.classList.add("nav-sticky");
    } else {
        navbar.classList.remove("nav-sticky");
    }
}

window.addEventListener('scroll', (ev) => {
    ev.preventDefault();
    windowScroll();
})

/*
// Smooth scroll 
var scroll = new SmoothScroll('#navbar-navlist a', {
    speed: 300,
    offset: 60
});
*/
// Navbar Active Class
/*
var spy = new Gumshoe('#navbar-navlist a', {
    // Active classes
    navClass: 'active', // applied to the nav list item
    contentClass: 'active', // applied to the content
    offset: 70
});
*/
// Contact Form
function validateForm() {
    var name = document.forms["myForm"]["name"].value;
    var email = document.forms["myForm"]["email"].value;
    var subject = document.forms["myForm"]["subject"].value;
    var comments = document.forms["myForm"]["comments"].value;
    document.getElementById("error-msg").style.opacity = 0;
    document.getElementById('error-msg').innerHTML = "";
    if (name == "" || name == null) {
        document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning'>*Please enter a Name*</div>";
        fadeIn();
        return false;
    }
    if (email == "" || email == null) {
        document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning'>*Please enter a Email*</div>";
        fadeIn();
        return false;
    }
    if (subject == "" || subject == null) {
        document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning'>*Please enter a Subject*</div>";
        fadeIn();
        return false;
    }
    if (comments == "" || comments == null) {
        document.getElementById('error-msg').innerHTML = "<div class='alert alert-warning'>*Please enter a Comments*</div>";
        fadeIn();
        return false;
    }

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            document.getElementById("simple-msg").innerHTML = this.responseText;
            document.forms["myForm"]["name"].value = "";
            document.forms["myForm"]["email"].value = "";
            document.forms["myForm"]["subject"].value = "";
            document.forms["myForm"]["comments"].value = "";
        }
    };
    xhttp.open("POST", "php/contact.php", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhttp.send("name=" + name + "&email=" + email + "&subject=" + subject + "&comments=" + comments);
    return false;
}

function fadeIn() {
    var fade = document.getElementById("error-msg");
    var opacity = 0;
    var intervalID = setInterval(function () {
        if (opacity < 1) {
            opacity = opacity + 0.5
            fade.style.opacity = opacity;
        } else {
            clearInterval(intervalID);
        }
    }, 200);
}

// typed

var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];
    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }
    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';
    var that = this;
    var delta = 200 - Math.random() * 100;
    if (this.isDeleting) { delta /= 2; }
    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }
    setTimeout(function () {
        that.tick();
    }, delta);
};

function typewrite() {
    if (toRotate === 'undefined') {
        changeText()
    }
    else
        var elements = document.getElementsByClassName('typewrite');
    for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #ffffff}";
    document.body.appendChild(css);
};
//window.onload(typewrite());


</script>

<style>


</style>
